import { createGlobalStyle } from 'styled-components'
import { token } from '../styles/theme'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('/fonts/roboto-v30-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto-v30-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto-v30-latin-100.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto-v30-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto-v30-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/open-sans-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/open-sans-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/open-sans-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/open-sans-v29-latin-500.woff') format('woff'), /* Modern Browsers */
        url('/fonts/open-sans-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/open-sans-v29-latin-500.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/open-sans-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/open-sans-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/open-sans-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/open-sans-v29-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/open-sans-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/open-sans-v29-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: Roboto, "Open Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    background-color: ${token.color.white};

  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

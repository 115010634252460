// eslint-disable-next-line import/no-anonymous-default-export
export const token = {
  grid: {
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '0.4rem',
  },
  font: {
    family:
      "Roboto, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', , Oxygen, Ubuntu, Cantarell,  'Helvetica Neue', sans-serif",
    thin: 100,
    light: 300,
    normal: 400,
    bold: 500,
    extraBold: 700,
    sizes: {
      small: '12px',
      medium: '15px',
      large: '18px',
      xlarge: '24px',
      xxlarge: '36px',
    },
  },
  color: {
    ligthPrimary: '#EBF0F3',
    primary: '#638C9F',
    darkPrimary: '#334A51',
    lightSecondary: '#FAF9F7',
    secondary: '#EAE9DF',
    darkSecondary: '#AEAC99',
    lightTertiary: '#EBF0F3',
    tertiary: '#F9F9F9',
    white: '#FFFFFF',
    lightGrey: '#E5E5E5',
    darkGrey: '#272727',
    success: '#0DFFC5',
    whatsAppGreen:'#25D366'
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
}

import { GlobalStyles } from '../styles/global'
import { ThemeProvider } from 'styled-components'
import { token } from '../styles/theme'
import Head from 'next/head'
import Script from 'next/script'

function MyApp({ Component, pageProps }) {
  return (
    <ThemeProvider theme={token}>
      <GlobalStyles />
      <Head>
        <meta lang="pt-BR" />
        <title>Juliana Nakashima - Osteopatia e Quiropraxia</title>
        <meta
          name="description"
          content="Trate a causa das suas dores através das técnicas de Osteopatia e Quiropraxia. Sem uso de remédios, é possível tratar dores na lombar, cabeça, pescoço, braço, articulações, bruxismo, cefaleia, intestino entre diversas outras."
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Head>
      <Component {...pageProps} />
    </ThemeProvider>
  )
}

export default MyApp
